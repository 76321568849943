.actionsteps-container {
  display: flex;
  padding: 3rem;
  gap: 2rem;
  justify-content: center;
}

.actionsteps-step {
  flex: 1;
  padding: 1.5rem;
  border: 2px solid #ced4da;
  border-radius: 15px;
  text-align: center;
  background-color: #ffffff;
  transition: transform 0.3s, box-shadow 0.3s;
  opacity: 0;
  animation: fadeInUp 0.5s forwards;
  animation-delay: calc(0.2s * var(--step-index));
}

.actionsteps-step-link {
  flex: 1;
  display: block;
  text-decoration: none;
  color: inherit;
  transition: all 0.3s;
}

/* Use this class for steps that shouldn't be clickable */
.non-clickable {
  pointer-events: none;
}

.actionsteps-icon {
  font-size: 3rem;
  color: #0070c0;
  margin-bottom: 1rem;
  transition: color 0.3s;
}

.actionsteps-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #343a40;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.actionsteps-desc {
  font-size: 1.2rem;
  font-weight: 400;
  color: #495057;
}

.actionsteps-step:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 30px rgba(0, 112, 192, 0.1);
  border-color: #0070c0;
}

.actionsteps-step:hover .actionsteps-icon {
  color: #004c99;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.actionsteps-intro {
  font-size: 2.2rem;
  color: #343a40;
  text-align: center;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

/* ... Your Existing CSS ... */

/* Responsive design for smaller screens */
@media only screen and (max-width: 768px) {
  .actionsteps-container {
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem;
  }

  .actionsteps-step {
    margin-bottom: 1rem;
  }

  .actionsteps-title {
    font-size: 1.5rem;
  }

  .actionsteps-desc {
    font-size: 1rem;
  }

  .actionsteps-icon {
    font-size: 2.5rem;
  }

  .actionsteps-intro {
    font-size: 1.8rem;
  }
}

/* Focus indication */
.actionsteps-step-link:focus .actionsteps-step,
.actionsteps-step-link:hover .actionsteps-step {
  transform: translateY(-10px);
  box-shadow: 0 10px 30px rgba(0, 112, 192, 0.1);
  border-color: #0070c0;
}

.actionsteps-step-link:focus .actionsteps-icon,
.actionsteps-step-link:hover .actionsteps-icon {
  color: #004c99;
}
