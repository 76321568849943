html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

.carousel-slide {
  display: flex;
  justify-content: center; /* Center logo horizontally */
  align-items: center; /* Center logo vertically */
  height: 150px; /* Fixed height for all logo containers */
  width: 100%; /* Full width to allow for responsive design */
  padding: 10px; /* Adds space around the logos */
  box-sizing: border-box; /* Include padding in width calculation */
}

.carousel-logo {
  height: 100%; /* Ensures the logo uses the full height and maintains aspect ratio */
  max-width: 100%; /* Prevents the logo from exceeding the width of the slide */
  object-fit: contain; /* Ensures the logos maintain their aspect ratios */
}

.carousel-heading {
  font-size: 2.4rem;
  color: #343a40; /* Neutral 800 for prominence */
  margin-bottom: 2rem; /* Provides some breathing space */
  font-weight: 600;
}

/* Mobile Styles */
@media screen and (max-width: 600px) {
  .carousel-slide {
    height: 100px; /* Smaller height for mobile */
    padding: 5px; /* Reduced padding */
  }

  .carousel-logo {
    /* Optionally, you can adjust the logo size if needed */
  }

  .carousel-heading {
    font-size: 1.6rem; /* Smaller font size for mobile */
    margin-bottom: 1rem; /* Less space below the heading */
  }
}
