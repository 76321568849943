.how-it-works-section {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin: 0 auto;
  padding: 2rem;
  color: #fff;
  background: radial-gradient(circle at 50% 50%, #1c1e29 0%, #111318 100%);
}

.top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.video-placeholder {
  width: 60%;
  height: 400px;
  background: #333;
  border-radius: 10px;
  position: relative;
  margin-left: 1rem;
  margin-top: 2rem;
  overflow: hidden; /* Add this line */
}

.video-placeholder iframe {
  width: 100%; /* Add this line */
  height: 100%; /* Add this line */
  object-fit: cover; /* Add this line */
}

.description-section {
  width: 50%; /* Set each child to take up 50% of the top section's width */
}

.play-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Add more styling for the play button */
}

.description-section h2 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
  margin-left: 3rem;
  width: 50%; /* Adjust as needed */
}

.description-section p {
  font-size: 1rem;
  margin-left: 3rem;
}

.steps-section {
  display: flex;
  justify-content: space-between;
}

.step {
  width: 30%; /* Adjust as needed */
}

.step h3 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.step h4 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.step p {
  font-size: 1rem;
}

@media (max-width: 768px) {
  .top-section,
  .steps-section {
    flex-direction: column;
    text-align: left;
  }

  .top-section p {
    text-align: left; /* Ensuring text aligns left for paragraphs in top section */
    margin-top: 1rem; /* Optionally add top margin for better spacing */
  }

  .video-placeholder,
  .step {
    width: 100%;
  }

  .description-section h2,
  .description-section p {
    margin-left: 0;
    width: 100%;
    text-align: left !important; /* Force text alignment */
    margin-top: 1rem; /* Added margin-top for better spacing */
  }
}
