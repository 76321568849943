.subtle-premium-prompt {
  text-align: center;
  margin-top: 20px;
  padding: 5px;
  background-color: #f4f4f4; /* Very light background color */
  color: #666; /* Subtle text color */
  border-radius: 4px;
  font-size: 0.85em;
  display: none; /* Initially hidden */
}

/* Show the prompt only after the user submits an answer */
.submitted .subtle-premium-prompt {
  display: block;
}
