/* BlogPostDetail.css */

/* Container for the blog post detail */
.blog-post-detail-container {
  max-width: 900px;
  margin: 40px auto;
  padding: 30px;
  background: #f5f7fc; /* Lighter background color */
  border-radius: 14px;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.05);
}

/* Image style */
.blog-post-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 12px;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

/* Title style */
.blog-post-title {
  font-size: 2.8em;
  font-weight: 600;
  color: #2d3748;
  text-align: center;
  margin-top: 20px;
}

/* Description style */
.blog-post-description {
  font-size: 1.6em;
  color: #718096;
  text-align: center;
  margin: 20px 0;
}

/* Content style */
.blog-post-content {
  font-size: 1.3em;
  color: #4a5568;
  line-height: 1.7;
  margin: 20px 0;
}

/* Subheader style */
.blog-post-content h2 {
  font-size: 2em;
  font-weight: 500;
  color: #2d3748;
  margin-top: 30px;
}

/* Paragraph style */
.blog-post-content p {
  margin: 20px 0;
}

/* List style */
.blog-post-content ul {
  margin: 20px 0;
  padding-left: 20px;
}

.blog-post-content li {
  margin: 10px 0;
}
