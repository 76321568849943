.homepage-exam {
  text-align: center;
  padding: 2rem;
}

.homepage-exam h1 {
  font-size: 2.4rem;
  color: #333;
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.exam-options {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-top: 1rem;
}

.exam-card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;
}

.exam-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.exam-card h2 {
  font-size: 1.8rem;
  color: #444;
  margin-bottom: 0.5rem;
}

.exam-card p {
  font-size: 1rem;
  color: #666;
}

/* Media queries */
@media (max-width: 768px) {
  .homepage-exam h1 {
    font-size: 2rem;
  }

  .exam-options {
    grid-template-columns: 1fr;
  }

  .exam-card {
    padding: 1rem;
  }

  .exam-card h2 {
    font-size: 1.5rem;
  }

  .exam-card p {
    font-size: 0.9rem;
  }
}
