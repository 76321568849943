/* Global Styles */
body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", Arial, sans-serif;
  background-color: #f5f5f5;
  color: #333;
  overflow-x: hidden;
}

/* Rest of your CSS code */

/* LandingPage Component Styles */
.landing-page {
  display: flex;
  flex-direction: column;

  background-color: #f5f5f5;
}

/* Carousel Section */
.carousel-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
  /* padding: 1rem 0; */
}

.carousel-title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 2rem;
  color: #333;
  padding-top: 2px;
}

.carousel-logo {
  max-height: 80px;
  object-fit: contain;
  margin: 0 10px;
}

/* Auth form */
.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.Auth-form {
  width: 400px;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.Auth-form-content {
  text-align: center;
}

.Auth-form-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.link-primary {
  color: #007aff;
  cursor: pointer;
}

.form-group {
  margin-bottom: 15px;
}

.form-control {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #953434;
}

.btn-primary {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #007aff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.text-center {
  text-align: center;
}

.mt-1 {
  margin-top: 5px;
}

.mt-2 {
  margin-top: 10px;
}

.mt-3 {
  margin-top: 15px;
}

/* Sign up */

.signup-link {
  margin-top: 1rem;
  text-align: center;
  color: #888;
  font-size: 0.9rem;
}

.signup-link a {
  color: #007aff;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s;
}

.signup-link a:hover {
  color: #0056b3;
}

@media only screen and (max-width: 768px) {
  /* Auth form */
  .Auth-form-container {
    padding: 2rem 1rem; /* Add padding so form doesn't touch the edges on tablets */
  }

  .Auth-form {
    width: 100%; /* Allow form to be full width with padding */
    max-width: 500px; /* Set a maximum width to maintain readability */
  }

  /* Adjust some other styles for better spacing and legibility on tablets */
  .btn-primary,
  .form-control {
    font-size: 18px;
  }

  .btn-primary {
    padding: 14px;
  }
}

/* === Mobile Phones (480px is a rough breakpoint for smaller phones) === */
@media only screen and (max-width: 480px) {
  /* Auth form */
  .Auth-form {
    width: 100%; /* Allow form to be full width on mobile */
    padding: 1.5rem; /* Adjust padding to keep consistent spacing */
  }

  /* Adjust font size and padding for better touch targets on mobile */
  .btn-primary,
  .form-control {
    font-size: 16px;
  }

  .btn-primary {
    padding: 12px;
  }

  /* Adjust form group margin */
  .form-group {
    margin-bottom: 12px;
  }
}
