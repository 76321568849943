/* ScoreComponent.css */
.score-container {
  max-width: 600px;
  margin: 40px auto;
  padding: 40px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.score-header {
  margin-bottom: 30px;
  color: #333333;
  font-size: 24px;
  font-weight: 600;
}

.score-category {
  padding: 10px 0;
  color: #555;
  font-size: 18px;
  border-bottom: 1px solid #eee;
}

.total-score {
  margin-top: 30px;
  font-size: 22px;
  font-weight: bold;
  color: #2c3e50;
}

.submit-score-btn {
  padding: 12px 30px;
  margin-top: 30px;
  font-size: 18px;
  color: white;
  background-color: #3498db;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submit-score-btn:hover {
  background-color: #2980b9;
}

.feedback-input {
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical; /* Allow users to adjust the height */
}
