.use-case-container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  font-family: Arial, sans-serif;
  margin-bottom: 2rem;
}

.use-case-container h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.use-case-cards {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.card {
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  width: calc(33.333% - 1rem);
  text-align: left;
}

.card img {
  width: 100%; /* Will take the full width of the parent */
  height: 200px; /* Set a fixed height */
  display: block;
  object-fit: cover; /* Will cover the entire content box, potentially cropping the image */
}
.card h3 {
  font-size: 1.5rem;
  color: #333;
  margin: 1rem;
}

.card p {
  font-size: 1rem;
  color: #666;
  margin: 1rem;
}

.card button {
  background: none;
  position: absolute;
  color: #007bff; /* Adjust the color to match your design */
  padding: 0;
  border: none;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem; /* Adjust the space between the text and the arrow */
}

.card button:hover,
.card button:focus {
  color: #0056e0; /* Adjust the hover color to match your design */
  text-decoration: none;
}

.card button::after {
  content: "→"; /* Unicode arrow */
  transition: transform 0.3s ease;
}

.card button:hover::after {
  transform: translateX(5px); /* Arrow moves right when hovered */
}

.get-started-link {
  color: #000; /* Or the color of your choice */
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1rem; /* Or the size of your choice */
  text-decoration: none; /* Removes the underline from the link */
  display: inline-block; /* Allows the use of margin and padding */
  transition: transform 0.2s ease-in-out;
  margin: 1rem;
}

.get-started-link:hover,
.get-started-link:focus {
  text-decoration: none; /* Ensures the underline doesn't reappear on hover/focus */
  transform: translateX(5px); /* Moves the link to the right on hover/focus */
}

.arrow-up-right {
  display: inline-block;
  transition: transform 0.2s ease-in-out;
}

.get-started-link:hover .arrow-up-right,
.get-started-link:focus .arrow-up-right {
  transform: translate(5px, -5px); /* Moves the arrow up and to the right on hover/focus */
}
@media (max-width: 1024px) {
  .use-case-cards {
    flex-direction: column;
  }

  .card {
    width: 100%;
    margin: 20px 0; /* Increase vertical margin */
    padding: 0 20px; /* Increase horizontal padding */
  }
}
