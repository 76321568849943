/* Choose.css */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.heading-primary {
  font-size: 36px; /* Adjust size as needed */
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.intro-text {
  font-size: 20px; /* Adjust size as needed */
  color: #333; /* Dark gray for readability */
  text-align: center;
  max-width: 600px; /* Optimal line length for reading */
  margin: 0 auto 40px; /* Centered with bottom margin */
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
}

.card {
  position: relative;
  width: 100%;
  max-width: 24rem; /* Increase the maximum width */
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  margin: 2rem;
  background: white;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.2);
}

.card img {
  display: block;
  width: 100%;
  height: 18rem; /* Increase the height for a larger card */
  object-fit: cover;
}

.card-title {
  position: absolute;
  width: 100%;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 0.5rem;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
}

.card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures consistent distribution */
  align-items: center;
  opacity: 0;
  transition: opacity 0.8s ease;
  padding: 1.5rem; /* Adjust padding to preference */
}

.card:hover .card-overlay {
  opacity: 1;
}

.card-description {
  color: #ffffff; /* Pure white for maximum contrast */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); /* Black shadow for legibility */
  text-align: center;
  padding: 0 1rem; /* Padding for better wrapping */
  font-size: 1.1rem; /* Slightly larger font size */
  font-weight: 500; /* Medium font weight for better visibility */
  margin-bottom: 0.5rem; /* Adjusting margin for button alignment */
  z-index: 10; /* Ensuring it's above the overlay */
}
.card-link {
  text-decoration: none;
  background: #2563eb;
  color: white;
  padding: 0.5rem 2rem;
  border-radius: 9999px;
  font-weight: bold;
  z-index: 10;
  width: auto; /* Adjusted to auto to accommodate text size */
  min-height: 40px; /* Ensures consistent height */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem; /* Adjust as necessary to lift from bottom edge */
}

.card-link:hover {
  background: #1d4ed8;
}
/* Tablet styles */
/* Tablet styles */
@media (max-width: 768px) {
  .card {
    margin: 1rem;
    max-width: none;
  }

  .card img {
    width: 100%;
    height: auto;
  }

  .card-title,
  .card-description,
  .card-link {
    font-size: 0.9rem;
  }

  .card-title {
    padding: 0.4rem;
    background: rgba(0, 0, 0, 0.7);
  }

  .card-description {
    padding: 0 0.5rem;
  }

  .card-link {
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;
  }
}

/* Mobile styles */
@media (max-width: 480px) {
  .card {
    margin: 0.5rem; /* Smaller margin for mobile */
  }

  .card img {
    width: 100%; /* Full width on mobile */
    height: auto; /* Maintain aspect ratio */
  }

  .card-title {
    font-size: 0.9rem; /* Smaller font size for mobile */
    padding: 0.3rem; /* Smaller padding on mobile */
    width: 88%;
  }

  .card-description {
    font-size: 0.8rem; /* Reduce font size for mobile */
    padding: 0 0.5rem; /* Maintain horizontal padding */
  }

  .card-link {
    padding: 0.4rem 0.8rem; /* Smaller padding for mobile */
    font-size: 0.8rem; /* Smaller font size for mobile */
    margin-bottom: 0.4rem; /* Adjust spacing from bottom */
  }
}
