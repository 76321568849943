.internal-links-section {
  padding: 30px 0;
  text-align: center;
  background-color: #f7f7f7;
}

.internal-links-heading,
.sub-heading {
  color: #333;
}

.internal-links-wrapper {
  padding: 0 10%;
}

.internal-links-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.internal-link-item {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  text-decoration: none; /* This removes the blue underline */
  position: relative;
  text-decoration: none;
}

.internal-link-image-wrapper {
  position: relative;
  overflow: hidden;
}

.internal-link-image {
  width: 100%;
  display: block;
  transition: transform 0.3s ease, filter 0.3s ease; /* Softer image scaling */
}

.internal-link-item:hover .internal-link-image {
  transform: scale(1.05); /* Slightly zoom in the image on hover */
  filter: brightness(0.8); /* Slightly darken the image to make text stand out */
}

.internal-link-title {
  position: absolute;
  bottom: 20px; /* Adjusted for better positioning */
  left: 50%;
  transform: translateX(-50%) translateY(20px) scale(0.9); /* Start slightly scaled down and below */
  transform-origin: bottom;
  opacity: 0;
  color: #fff; /* White text for contrast */
  background: rgba(20, 20, 20, 0.75); /* Dark semi-transparent background for legibility */
  padding: 8px 16px; /* Padding for a better text block */
  border-radius: 15px; /* Rounded corners for the background */
  font-weight: 600; /* Bold but not too heavy */
  font-size: 1.2rem; /* Slightly larger font size */
  transition: all 0.45s ease-in-out; /* Smooth transition for all properties */
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  transition-delay: 0.1s; /* Delays the animation for a staged effect */
  will-change: transform, opacity; /* Optimizes for animations */
}

.internal-link-item:hover .internal-link-title {
  transform: translateX(-50%) translateY(0) scale(1); /* Moves to final position and scales to 100% */
  opacity: 1; /* Fully visible */
}

.internal-link-desc {
  color: #606c76;
  font-size: 0.9rem;
  margin-top: 10px;
}

/* animated stuff */
.internal-link-image-wrapper {
  position: relative;
}

.internal-link-image.static,
.internal-link-image.animated {
  width: 100%;
  display: block;
}

.internal-link-image.animated {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.internal-link-item:hover .internal-link-image.animated {
  opacity: 1;
}

.internal-link-item:hover .internal-link-image.static {
  opacity: 0;
}

/* Ensure video is not playing until hovered */
.internal-link-image.animated {
  pointer-events: none;
}

.internal-link-item:hover .internal-link-image.animated {
  /* Play the video when the item is hovered */
  pointer-events: auto;
}

/* .motivational-message-section */
.motivational-message-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  background-color: #fff;
  color: #333;
  text-align: center;
  overflow: hidden;
}

.motivational-message {
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 2.5em; /* Respectable size, not too dominating */
  font-weight: 400; /* Regular weight for this part */
  margin-bottom: 1rem; /* Space before the second message */
  opacity: 1; /* Fully visible */
}

.revealed-message {
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 2.8em; /* Slightly larger size for emphasis */
  font-weight: 700; /* Bolder to stand out */
  color: #555; /* Initially same color as the first message */
  opacity: 0; /* Start fully transparent */
  transform: scale(0.9); /* Start slightly smaller */
  transition: opacity 1.5s ease-in-out, transform 1.5s ease-in-out, color 1.5s ease-in-out; /* Delayed for effect */
}

/* Make sure this matches the element that the observer is targeting */
.motivational-message-section.reveal .revealed-message {
  opacity: 1; /* Fade in to full visibility */
  transform: scale(1); /* Scale up to full size */
  color: #000; /* Transition to a more prominent color */
}

/* introduciton section */
.introduction-section {
  max-width: 1200px; /* Adjust to match the width of your content area */
  margin: 0 auto; /* Centers the section */
  padding: 20px; /* Adjust the padding as needed */
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; /* Specified font family */
}

.introduction-title {
  color: #333; /* Adjust the color to match your design */
  font-size: 2rem; /* Adjust to match the size of your other headings */
  font-weight: 600; /* Adjust the weight as needed */
  margin-bottom: 0.5rem; /* Reduce the space below the title */
  text-align: center; /* Center align the title */
}

.introduction-text-middle {
  color: #666; /* Adjust the color for readability */
  font-size: 1rem; /* Match the size of your body text */
  line-height: 1.6; /* For better readability */
  /* margin-bottom: 1rem; */
  text-align: center; /* Align the text to the left or justify as preferred */
}

@media (max-width: 1200px) {
  .internal-links-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .internal-links-container {
    grid-template-columns: 1fr;
  }
  .introduction-title {
    font-size: 1.5rem; /* Smaller font size for smaller screens */
  }

  .introduction-text {
    font-size: 0.9rem; /* Smaller font size for smaller screens */
  }
}
