/* AboutUs Component Styling Enhanced */

.about-us-section {
  background-color: #f9f9f9;
  padding: 80px 20px;
  text-align: center;
}

.about-us-title {
  font-size: 2.75rem;
  font-weight: 700;
  margin-bottom: 40px;
  color: #003366; /* A deep navy blue for a strong presence */
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.about-us-subtitle {
  font-size: 1.85rem;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 25px;
  color: #004b8d; /* A slightly lighter shade of navy for distinction */
  text-transform: uppercase;
  letter-spacing: 1px;
}

.about-us-description {
  font-size: 1rem;
  line-height: 1.65;
  color: #444; /* A softer black for less harshness */
  margin-bottom: 40px;
}

.about-us-mission-vision {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin-top: 50px;
}

.about-us-item {
  flex-basis: calc(33.333% - 40px); /* Three items per row with proper gap */
  padding: 40px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.about-us-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.about-us-icon {
  font-size: 50px; /* Large icons for emphasis */
  margin-bottom: 15px;
  color: #007acc; /* A bright blue that stands out yet ties in with the theme */
  transition: color 0.3s ease;
}

.about-us-icon:hover {
  color: #005b99; /* A darker blue for hover state */
}

.about-us-button {
  background-color: #007acc; /* Consistent with the icon color for uniformity */
  color: white;
  padding: 15px 35px;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  margin-top: 30px;
}

.about-us-button:hover {
  background-color: #005b99; /* Darker blue for the button hover state */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  transform: translateY(-3px);
}

/* Responsive Design Adjustments for Mobile Experience */
@media (max-width: 768px) {
  .about-us-section {
    padding: 60px 15px;
  }

  .about-us-title {
    font-size: calc(2em + 1vw); /* Dynamic size for mobile devices */
  }

  .about-us-subtitle {
    font-size: calc(1.5em + 1vw);
  }

  .about-us-mission-vision {
    gap: 20px; /* Adjust gap for mobile devices */
    margin-top: 40px;
  }

  .about-us-item {
    flex-basis: 100%; /* Full width for a single column layout on mobile */
    padding: 30px;
  }

  .about-us-button {
    padding: 12px 30px; /* Adjust for mobile ergonomics */
  }
}
