/* FullGMATExam.css */

.full-gmat-exam {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background: #ffffff; /* Brighter and cleaner background */
  border-radius: 10px; /* Slightly rounded corners for a modern feel */
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1); /* Softer shadow */
  font-family: "Arial", sans-serif; /* Modern and readable font */
}

.start-exam {
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 4rem;
}

.start-exam h1 {
  margin-bottom: 1rem;
  color: #333333; /* Dark grey for better readability */
  font-size: 2rem; /* Larger title */
}

.exam-section {
  margin-top: 1rem;
}

.navigation-buttons {
  text-align: center;
  margin-top: 20px;
}

button {
  padding: 0.5rem 1rem; /* More proportional padding */
  margin: 0.5rem; /* Space between buttons */
  border: 1px solid transparent; /* Refined borders */
  background-color: #0056b3; /* A more modern blue */
  color: white;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s, transform 0.2s; /* Smooth transition for interaction */
}

button:hover {
  background-color: #003d82;
  transform: translateY(-2px); /* Slight raise effect */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

button:disabled {
  background-color: #cccccc;
  color: #666666; /* Grayed out text for disabled state */
  cursor: not-allowed;
  box-shadow: none; /* No shadow for disabled buttons */
}

.start-exam label {
  display: block;
  margin: 0.5rem 0;
}

.start-exam input[type="radio"] {
  margin-right: 0.5rem;
}

.next-btn {
  padding: 10px 20px;
  border: none;
  background-color: #78909c; /* Neutral color / color: white; cursor: pointer; border-radius: 5px; font-size: 16px; transition: background-color 0.2s; / Smooth transition for hover effect */
}

.next-btn {
  background-color: #007bff; /* GMAT-like blue color / color: white; transition: background-color 0.2s; / Smooth transition for hover effect */
}

/* Responsive Design: Adjusting for smaller screens */
@media (max-width: 768px) {
  .full-gmat-exam {
    padding: 1rem;
    margin: 1rem;
  }

  .start-exam h1 {
    font-size: 1.5rem;
  }

  button {
    padding: 0.5rem; /* Smaller padding on smaller screens */
  }
}
