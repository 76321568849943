/* PremiumChatInterface Container Styles */
.premium-chat-interface {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 20px;
}

/* Chat History Styles */
.chat-history {
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
  background-color: #f7f7f7;
}

/* Individual Chat Message Styles */
.message {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  background-color: #e9e9e9;
  border-left: 3px solid #0070c0;
}

.message.user {
  background-color: #d0eaff;
  border-left-color: #005999;
}

.message.api {
  background-color: #f0f0f0;
  border-left-color: #333;
}

/* Input and Button Container Styles */
.user-input-container {
  display: flex;
  gap: 10px; /* Adjust gap as necessary */
  margin-top: 10px;
}

.user-input {
  flex: 1;
  padding: 10px 15px; /* Comfortable padding for typing */
  border: 2px solid #0070c0; /* A border color that matches the button */
  border-radius: 8px; /* Rounded corners for the input */
  font-family: "Helvetica Neue", Arial, sans-serif; /* Consistent font with the rest of the interface */
  font-size: 16px; /* Sufficient size for readability */
  color: #333; /* Dark color for text */
  outline: none; /* Remove the default focus outline */
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1); /* Inner shadow for depth */
  transition: border-color 0.3s; /* Smooth transition for focus */
}

.user-input:focus {
  border-color: #005999; /* Darker border color on focus for visibility */
}

/* Send Button Styles */
.send-button {
  padding: 10px 20px; /* Padding to match the input height */
  background-color: #0070c0; /* Button color */
  color: white; /* Text color */
  border: none; /* No border */
  border-radius: 8px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Smooth background transition */
  font-family: "Helvetica Neue", Arial, sans-serif; /* Consistent font with the rest of the interface */
  font-size: 16px; /* Matching font size with the input */
}

.send-button:hover {
  background-color: #005999; /* Slightly darker on hover for interaction feedback */
}

.send-button:disabled {
  background-color: #999; /* Disabled state */
  cursor: not-allowed; /* Not-allowed cursor for disabled button */
}

/* Loading Message Styles */
.loading-message {
  text-align: center;
  color: #0070c0;
  font-style: italic;
}

/* Error Message Styles */
.error-message {
  color: #ff0000;
  text-align: center;
  margin-top: 10px;
}

/* Markdown Specific Styles */
/* If you want to add specific styles for markdown rendered content,
     you can add them here. For example: */

.markdown-content h1 {
  font-size: 1.5em;
  color: #005999;
}

.markdown-content ul,
.markdown-content ol {
  padding-left: 20px;
  list-style-position: inside;
}

.markdown-content code {
  background-color: #f7f7f7;
  padding: 2px 4px;
  border-radius: 4px;
  font-family: "Courier New", monospace;
}

.markdown-content pre {
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 8px;
  overflow-x: auto;
}
