/* QuantitativeQuestion.css */

.question-container {
  font-family: "Arial", sans-serif;
  background-color: #f8f8f8;
  border-radius: 5px;
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 20px;
  max-width: 800px;
  margin: auto;
}

.question-container h2 {
  color: #333;
  border-bottom: 2px solid #aaa;
  padding-bottom: 5px;
}

.question-image img {
  width: 100%;
  height: auto;
  margin: 15px 0;
}

.question-text {
  margin: 20px 0;
}

.options label {
  display: block;
  margin: 10px 0;
}

.options input[type="radio"] {
  margin-right: 10px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.submit-btn,
.next-btn {
  padding: 10px 20px;
  border: none;
  background-color: #78909c; /* Neutral color */
  color: white;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.2s; /* Smooth transition for hover effect */
}

.next-btn {
  background-color: #007bff; /* GMAT-like blue color */
  color: white;
  transition: background-color 0.2s; /* Smooth transition for hover effect */
}

.next-btn:hover {
  background-color: #0056b3; /* A slightly darker shade for hover state */
}

.submit-btn:hover,
.next-btn:hover {
  background-color: #5f6a79; /* Slightly darker shade on hover */
}

/* Toggle Switch Styles */
.practice-mode-toggle {
  margin: 20px 0;
  text-align: center;
}

.switch-label {
  margin-right: 10px;
  font-weight: bold;
}

.practice-mode-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.practice-mode-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* Subtle premium feature prompt */
.premium-prompt {
  margin-top: 15px;
  text-align: center;
  font-size: 0.9em;
  color: #555;
}

.premium-prompt a {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
}

.premium-icon {
  display: inline-block;
  color: #ffc107; /* Gold color for premium feel */
  font-size: 1.2em; /* Slightly larger icon size */
  margin-right: 5px;
}

.premium-invite {
  text-align: center; /* Center the button horizontally */
  margin-top: 20px; /* Spacing between the explanation and the button */
}

.premium-invite.fixed-bottom {
  position: fixed;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  z-index: 1000; /* Ensure it's above other elements */
}
/* Tooltip styles for premium upgrade prompt */
.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-text {
  visibility: hidden;
  width: 280px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -140px;
  font-size: 0.85em;
  transition: opacity 0.3s;
}

.tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

/* Button that triggers the tooltip */
/* Premium Upgrade Button Style */

.premium-icon {
  height: 1em; /* Adjust size as needed */
  width: auto;
  vertical-align: middle; /* Align the icon vertically */
  margin-right: 8px; /* Space between icon and text */
}

.premium-upgrade-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6c5ce7; /* Color from your example */
  color: #fff;
  border: none;
  border-radius: 20px; /* Rounded edges */
  padding: 10px 20px;
  font-size: 0.9em;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25); /* Subtle shadow */
  transition: all 0.3s ease;
}

.premium-upgrade-btn:hover {
  background-color: #5a4dad; /* Darker shade on hover */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25); /* Larger shadow on hover */
}

/* Rest of your styles remain the same */

/* Style for chatbot interface for premium users */
.chatbot-interface {
  /* Styles for chatbot interface */
}

.option-label.correct-answer {
  background-color: #c8e6c9; /* Light green for correct answers */
}

.option-label.incorrect-answer {
  background-color: #ffcdd2; /* Light red for incorrect answers */
}

/* Disabled radio buttons after checking the answer */
.options input[type="radio"]:disabled + label {
  color: #b0bec5; /* Grey out non-selected options */
}

/* Style for correct answer text */
.options input[type="radio"]:checked + label.correct-answer {
  color: #2e7d32; /* Dark green text for correct answers */
}

/* Style for incorrect answer text */
.options input[type="radio"]:checked + label.incorrect-answer {
  color: #d32f2f; /* Dark red text for incorrect answers */
}

/* Bottom fixed bar for premium hint */
.premium-hint {
  position: fixed;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  z-index: 1000;
  font-family: "Inter", sans-serif; /* Modern, clean font */
  color: #ffffff;
  opacity: 0.9; /* Slightly see-through for subtlety */
  transition: opacity 0.3s ease;
  align-self: center;
  text-align: center;
}

.premium-hint:hover {
  opacity: 1; /* Fully opaque on hover for focus */
}

.premium-hint button {
  background: rgba(44, 44, 46, 0.8); /* Slightly see-through */
  color: #ffffff;
  border: none;
  border-radius: 30px; /* Rounded corners for a modern feel */
  padding: 12px 24px;
  font-size: 0.9em;
  font-weight: 500;
  cursor: pointer;
  align-self: center;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

.premium-hint button:hover {
  background-color: rgba(58, 58, 60, 0.8); /* Darker background on hover */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Slightly deeper shadow on hover */
}

html {
  scroll-behavior: smooth;
}

.videos-section {
  width: 100vw; /* Full viewport width */
  position: relative; /* For full width */
  left: 50%; /* Move to the center */
  right: 50%;
  transform: translateX(-50%); /* Offset the left positioning */
  margin-top: 10rem; /* Space from the top content */
  /* Adjust the height as necessary, remove the background or set it to a desirable color */
  background: none; /* Or any background color you want */
  /* Add more styles as needed for your video section */

  display: flex; /* Arrange children horizontally */
  justify-content: center; /* Center children horizontally */
  gap: 1rem; /* Space between children */
}

.videos-section img {
  max-width: 45%; /* Adjust as needed */
  border-radius: 10px; /* Rounded corners for a smoother look */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Shadow for a 3D effect */
  object-fit: contain;
}

.video-container {
  position: relative;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
}

.option-label.correct {
  background-color: #d4edda; /* Green background */
  border-color: #c3e6cb;
  color: #155724; /* Dark green text */
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .buttons {
    flex-direction: column;
  }

  .submit-btn,
  .next-btn {
    width: 100%;
    margin-bottom: 10px;
  }

  .premium-upgrade-btn {
    position: static; /* Reset position for mobile */
    transform: none;
    display: block;
    margin: 10px auto; /* Center the button on mobile */
  }
  .premium-invite.fixed-bottom {
    width: calc(100% - 40px); /* Full width with padding */
    left: 20px; /* Align with the container padding */
    transform: none;
  }
  .premium-hint {
    width: calc(100% - 40px);
    left: 20px;
    transform: none;
  }
}

@media (max-width: 480px) {
  .question-container {
    padding: 10px;
  }

  .submit-btn,
  .next-btn {
    padding: 8px 16px; /* Smaller padding for smaller screens */
  }

  .question-text,
  .options label {
    font-size: 14px;
  }

  .premium-prompt {
    font-size: 0.8em; /* Adjust size for smaller screens */
  }
  .premium-hint button {
    padding: 10px 18px;
    font-size: 0.8em;
  }
}
