.exam-insight-container {
  max-width: 800px;
  margin: 80px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background: white;
}

.question-review {
  margin-bottom: 30px;
}

h3 {
  margin-bottom: 10px;
  color: #333;
}

.question {
  font-size: 18px;
  color: #555;
  margin-bottom: 15px;
}

.options {
  margin-bottom: 15px;
}

.option {
  padding: 5px 10px;
  margin: 5px 0;
  border-radius: 5px;
  background-color: #f9f9f9;
  color: #333;
}

.answer {
  font-weight: bold;
}

.correct {
  color: green;
}

.incorrect {
  color: red;
}

.explanation {
  margin-top: 20px;
  padding: 15px;
  background-color: #f1f8ff;
  border-left: 4px solid #0366d6;
}

/* Responsive Design */
@media only screen and (max-width: 768px) {
  .exam-insight-container {
    padding: 15px;
    margin-top: 10rem;
  }

  .question {
    font-size: 16px;
  }

  .option {
    padding: 3px 7px;
  }
}

@media only screen and (max-width: 480px) {
  .exam-insight-container {
    margin: 3rem;
    padding: 10px;
  }

  .question {
    font-size: 14px;
  }

  .option {
    padding: 2px 5px;
  }
}
