.Modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

.Modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 80%; /* Adjust as needed */
  max-width: 500px; /* Maximum width */
}

.Modal-content h2 {
  margin-top: 0;
  color: #333;
}

.Modal-content p {
  color: #555;
  margin: 20px 0;
}

.Modal-content button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.Modal-content button:hover {
  background-color: #0056b3;
}
