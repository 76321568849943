.all-in-one-tool-container {
  background: radial-gradient(circle at 50% 50%, #1c1e29 0%, #111318 100%);
  color: white;
  font-family: "Arial", sans-serif;
  padding: 2rem;
}

.header-section p {
  text-align: center;
}

.header-section h2,
.learning-section .text-block h2 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
  margin-left: 3rem;
  color: white;
  text-align: center;
}

.header-section,
.learning-section {
  font-size: 1rem;
  margin-bottom: 2rem;
  text-align: center;
}

.learning-section {
  display: flex;
  margin-bottom: 2rem;

  border-radius: 10px;
  overflow: hidden;
}

h3,
p {
  text-align: left; /* Aligns the text to the left */
}

.image-container {
  width: 50%;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    to right,
    #0b0e1f,
    #0c0f20,
    #0d0e21,
    #0e0f23,
    #0e1124,
    #0f1024,
    #0f1125,
    #0f1225,
    #0f1329,
    #1f2030,
    #1f2031,
    #1f2032,
    #202132,
    #202233,
    #202335,
    #202436,
    #212336,
    #212334
  );
  border-radius: 5px;
}

.image-container img {
  width: 100%;
  max-width: 400px; /* Reduced from 500px to 400px */
  height: auto;
  border-radius: 10px;
}

.text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px; /* Add padding on top */
  padding-left: 10px;
  width: 50%; /* Ensures text content also takes up half the width */
  background: transparent; /* Ensure text block has no background */
}

.text-block {
  border-bottom: 1px solid white; /* Add a white line between the text blocks */
  padding: 20px 0; /* Add vertical padding for even spacing */
}

.text-block:last-child {
  border-bottom: none; /* Remove the border from the last text block */
}

@media (max-width: 1024px) {
  .learning-section {
    flex-direction: column;
  }

  .image-container,
  .text-content {
    width: 100%;
  }

  .image-container img {
    max-width: 100%;
  }
}

/* Media Queries for Tablets and Phones */
@media (max-width: 768px) {
  .header-section h2,
  .learning-section .text-block h2 {
    font-size: 2.5rem; /* Decrease font size for smaller screens */
    margin-left: 0; /* Center-align text on smaller screens */
  }

  .image-container img {
    max-width: 300px; /* Reduce max-width for smaller screens */
  }

  .text-content {
    width: 100%; /* Make text content full width on smaller screens */
    padding-left: 0; /* Remove left padding on smaller screens */
  }

  .text-block {
    padding: 10px 0; /* Reduce vertical padding for smaller screens */
  }
}
