.text-accent {
  color: #00a1ab;
}

.fs-500 {
  font-size: 1.3125rem;
  margin: 0;
}

.mb {
  margin-bottom: 0.5em;
}

.plans {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 2em 0;
}

.plan {
  margin: 0 auto;
  width: calc(33.333% - 1rem); /* Adjust the width as necessary, subtracting the gap */
}

.plan--light {
  color: #4e4e4e;
  background: linear-gradient(-45deg, #e5e3e8, #fafafa);
}

.plan--light .plan-price {
  color: #00a1ab;
}

.plan--light .btn {
  color: #fff;
  background: #4e4e4e;
}

.plan--accent {
  color: #fff;
  background: linear-gradient(-45deg, #00a1ab, #3741a0);
}

.plan--accent .btn {
  color: #4e4e4e;
  background: #fff;
}

.plan-title {
  text-transform: uppercase;
  margin: 0 0 1em;
}

.plan-price {
  margin: 0;
  font-size: 2rem;
  line-height: 1;
  font-weight: 900;
}

.plan-price span {
  display: block;
  font-size: 1.5625rem;
  font-weight: 300;
}

.plan-description {
  margin: 2em 0;
  line-height: 1.5;
}

.btn {
  display: inline-block;
  padding: 0.5em 0.75em;
  border-radius: 0.25em;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 700;
  color: #fff;
  background: #00a1ab;
  transition: 0.3s;
}

.btn:hover {
  opacity: 0.75;
}

.btn-mb {
  margin-bottom: 3em;
}

.btn--light {
  color: #4e4e4e;
  background: #fff;
}

.btn--dark {
  color: #fff;
  background: #4e4e4e;
}

/* pricing card new css start here */
section.pricing_section {
  padding: 120px 6px;
  padding-top: 60px; /* Adjust the top padding to reduce white space */
  background-color: #fcfcff;
}

.pricing_inner_content {
  height: 100%;
  background-color: #fff;
  border-radius: 8px;
  padding: 32px;
  box-shadow: 0 0 8px #e8edf6, 0 0 8px #fff;
}

.pricing_inner_content h4 {
  font-size: 24px;
  line-height: 1.4;
  font-weight: 400;
  margin-bottom: 16px;
}

li.price_list_content {
  grid-column-gap: 8px;
  align-items: center;
  display: flex;
  margin-bottom: 10px;
}

section.pricing_section ul {
  padding-left: 0;
  list-style-type: none;
  border-top: 1px solid #ebf0f6;
  padding-top: 16px;
}

li.price_list_content span {
  color: #19191b;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
}

li.price_list_content:last-child {
  margin-bottom: 0;
}

.a-pricing-button-wrapper a {
  padding: 8px 24px 8px 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
  transition: all 0.2s;
  display: inline-block;
  color: #fff;
  background-color: #0e1113;
  border-radius: 4px;
  margin-bottom: 32px;
}
.black_button a {
  background: transparent;
  color: black;
  font-weight: 500;
}
.a-pricing-button-wrapper a svg {
  position: relative;
  right: -3px;
  transition: 0.3s all ease-in-out;
  width: 18px;
}
.a-pricing-button-wrapper a svg:hover {
  right: -6px;
  /* transform: translateX(15px); */
}
.a-pricing-button-wrapper a:hover svg {
  right: -6px;
  /* transform: translateX(15px); */
}

.single-product {
  max-width: 33.333%;
  flex: 0 0 33.333%;
  margin: 0 auto;
}

section.pricing_section h5 {
  font-size: 40px;
  font-weight: 400;
  line-height: 48px;
  margin: 32px 0;
}

section.pricing_section span {
  color: #6b7280;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1.4;
}

/* nav{
    overflow: scroll;
} */

svg {
  fill-rule: evenodd;
}

.gmat-header {
  font-family: "Poppins", sans-serif; /* A modern, clean font */
  font-size: 2.5rem; /* Prominent but not overwhelming */
  font-weight: 700; /* Bold but not as heavy as 900 */
  color: #0056b3; /* A deep, professional blue */
  text-align: center; /* Center align the text */
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  line-height: 1.2; /* Tighter line height for a compact look */
  text-shadow: 1px 1px 2px rgba(50, 50, 50, 0.1); /* Soft shadow for depth */
}

/* Add this new class for the subtitle */
.h1-subtitle {
  font-family: "Poppins", sans-serif; /* Consistency with the header font */
  max-width: 700px; /* Constrain width to cause text to wrap */
  margin-left: auto; /* These two margin rules center the block */
  margin-right: auto;
  font-size: 1.25rem; /* Large enough to be readable but not as prominent as the header */
  color: #333333; /* Dark color for readability */
  text-align: center; /* Center align the text */
  margin-top: -0.5rem; /* Pulls the subtitle closer to the header */
  margin-bottom: 2rem; /* Space before the content starts */
  font-weight: 400; /* Regular weight for a lighter feel than the header */
  opacity: 0.8; /* Slightly transparent for a softer look */
  line-height: 1.5; /* Adjusts space between lines of text */
}

.special-offer {
  font-family: "Poppins", sans-serif; /* Consistency with the overall design */
  font-size: 1.15rem; /* Sized for emphasis but not overwhelming */
  color: #4a4a4a; /* Professional, subdued color for serious appeal */
  text-align: center; /* Center align the text */
  margin-top: 1rem; /* Gives some space from the subtitle or other elements */
  margin-bottom: 2rem; /* Space before the content starts */
  font-weight: 500; /* Boldness for emphasis but not overly heavy */
  display: block; /* To ensure it takes its own line and is easily centered */
  background-color: #e9f8f2; /* A soft, inviting background color */
  border: 1px solid #b2d8d8; /* A light border for subtle emphasis */
  border-radius: 15px; /* Rounded corners for a modern, friendly feel */
  padding: 8px 20px; /* Comfortable padding around the text */
  max-width: fit-content; /* Ensures the background only extends around the text */
  margin-left: auto; /* These two margin rules center the block horizontally */
  margin-right: auto;
}

/* pricing card new css end here */
@media only screen and (min-width: 1199px) {
  .pricing_info {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem; /* Adds space between the cards if they wrap */
  }
  h1 {
    font-size: 2.5rem; /* Slightly larger size on tablets */
  }
}

@media only screen and (max-width: 991px) {
  section.pricing_section .col-md-6 {
    margin-bottom: 24px;
  }

  h1 {
    font-size: 2rem; /* Smaller size on mobile devices for better fit */
  }

  section.pricing_section {
    padding: 60px 0;
  }
}
