.hero-section {
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(circle at 50% 50%, #1c1e29 0%, #111318 100%);
  color: white;
  padding-top: 150px;
  padding-bottom: 150px;
  position: relative;
  overflow: hidden;
  text-align: left; /* Align text to the left */
}

/* Container for the content and image */
.hero-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px; /* Adjust to your preference */
  margin: 0 auto;
}

/* Styling for the title and align left*/
.hero-title {
  font-size: 4rem;
  text-align: left;
  color: whitesmoke;
  font-weight: 400;
  margin: 0;
}

@keyframes wave {
  0%,
  100% {
    transform: translateX(0) scale(1);
  }
  25% {
    transform: translateX(-2.5%) scale(1.05);
  }
  50% {
    transform: translateX(0) scale(1);
  }
  75% {
    transform: translateX(2.5%) scale(0.95);
  }
}

.hero-svg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  z-index: 0;
  animation: wave 6s infinite ease-in-out;
}

/* Content styling */
.hero-content {
  flex-basis: 50%;
  z-index: 2; /* Above SVG background */
}

/* Styling for the subtitle */
.hero-subtitle {
  font-size: 1.25rem;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 30px;
  max-width: 640px;
}

/* Container for buttons */
.hero-buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-bottom: 50px;
}

/* Styling for buttons */
.hero-button {
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: 500;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

/* Primary button */
.hero-button-primary {
  background: white;
  color: #0d6efd;

  transition: all 0.3s ease;
}

.hero-button-primary:hover {
  background: #0d6efd;
  color: white;
  box-shadow: 0 0 10px #0d6efd;
}
/* Secondary button (outline) */
.hero-button-secondary {
  background: transparent;
  color: white;
  border: 2px solid white;
}

/* Hover effects for buttons */
.hero-button:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
}

/* Image and video styling */
.picture-hero {
  flex-basis: 50%; /* Adjust based on desired video size */
  position: relative; /* Keep as relative */
  z-index: 2; /* Maintain above background */
  display: flex; /* Add flex to center video */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.picture-hero video {
  max-width: 100%; /* Ensure video does not exceed the container width */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px; /* Optional: for rounded corners */
}

.picture-hero img {
  width: 100%;
  height: auto;
  display: block;
}

/* Responsive adjustments */
/* ... Other styles ... */

/* Responsive adjustments */
@media (max-width: 1024px) {
  .hero-title {
    font-size: 3rem;
  }

  .hero-subtitle {
    font-size: 1rem;
    margin: 15px 0 20px;
  }

  .hero-buttons {
    flex-direction: column;
    gap: 15px;
  }

  .hero-button {
    width: 100%;
    padding: 12px 20px;
    margin-bottom: 15px;
    font-size: 0.9rem;
  }

  .picture-hero {
    order: -1;
    margin-bottom: 20px;
  }

  .picture-hero img {
    max-width: 80%;
    margin: 0 auto;
  }
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .hero-section {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .hero-inner {
    flex-direction: column;
  }

  .hero-content,
  .picture-hero {
    flex-basis: auto;
    width: 100%;
    text-align: center;
  }

  .hero-title {
    font-size: 2.5rem;
    margin: 15px 20px 20px;
  }

  .hero-subtitle {
    font-size: 1rem;
    margin: 15px 20px 20px;
  }

  .hero-buttons {
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;
  }

  .hero-button {
    width: 60%; /* Reduce width */
    padding: 10px 15px; /* Reduce padding */
    margin-bottom: 15px;
    font-size: 0.8rem; /* Reduce font size */
  }

  .picture-hero {
    order: -1;
    margin-bottom: 20px;
  }

  .picture-hero img {
    max-width: 80%;
    margin: 0 auto;
  }
}
