/* Main profile container */
.profile-container {
  max-width: 600px;
  margin: 80px auto;
  padding: 20px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
}

/* Profile content */
.profile-content {
  font-family: "Helvetica Neue", sans-serif;
  color: #2c2c2e;
}

/* Titles and text */
.profile-content h1,
.profile-content h3,
.profile-content p {
  margin: 16px 0;
}

/* Button styles */
.button {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 14px;
  color: #fff;
  background-color: #0070c0;
  border: none;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.2s;
}

.button:hover {
  background-color: grey;
  color: #2c2c2e;
}

/* Divider style */
.divider {
  border-top: 1px solid #ddd;
  margin: 20px 0;
}

/* Aligns the icon and text */
.info-section {
  display: flex;
  align-items: center;
}

.info-section img {
  margin-right: 10px;
}

/* Section titles */
.section-title {
  font-size: 18px;
  color: #333;
  margin-bottom: 5px;
}

/* Details text */
.details-text {
  margin-left: 40px; /* Indent detail text for clarity */
}
