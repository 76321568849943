.comparison-container {
  display: flex;
  justify-content: center;
  gap: 60px;
  padding: 20px;
}

.column {
  flex: 1;
  max-width: 450px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 30px;
  text-align: center;
  transition: box-shadow 0.3s ease; /* Only apply transition to box-shadow */
}

.column:hover {
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.15); /* Soften the hover effect shadow */
}

.column img {
  width: 100%;
  max-height: 220px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 20px;
}

.column h2 {
  font-size: 24px;
  margin-bottom: 20px;
  position: relative;
  color: #333; /* Neutral color for both titles */
}

.column h2::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  height: 3px; /* Subtler thickness for the underline */
  background: #0070c0; /* Keep the blue underline for both */
  border-radius: 2px;
  transition: width 0.3s ease;
  width: 0;
}

.column:hover h2::after {
  width: 100%;
}

/* Emphasize the "CSG" column by adding a slight blue top border */
.column.generator {
  border-top: 4px solid #0070c0; /* Adds a blue top border */
  margin-top: -4px; /* Adjusts position to align with the traditional column */
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.list-item {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #333;
  margin-bottom: 15px;
  line-height: 1.4;
}

.list-item-icon {
  margin-right: 10px;
  font-size: 20px;
}

.marketing-text {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  text-align: justify;
  margin-bottom: 1.5rem;
}

/* Additional adjustments for mobile devices */
@media only screen and (max-width: 768px) {
  .comparison-container {
    flex-direction: column;
    gap: 30px;
  }

  .column {
    max-width: none;
    margin-bottom: 15px;
  }

  .column img {
    max-height: 180px;
  }

  .column h2 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .list-item {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .marketing-text {
    font-size: 14px;
  }
}

@media only screen and (max-width: 480px) {
  .column {
    padding: 20px;
  }
}

.list-item-icon.checkmark::before {
  content: "✔"; /* Checkmark icon */
  color: #4caf50; /* Green color */
}

.list-item-icon.xmark::before {
  content: "✘"; /* Cross icon */
  color: #ff4d4d; /* Red color */
}
