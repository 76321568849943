/* Navbar */
.navBarStyle {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 10px 30px;
  background: radial-gradient(circle at 50% 50%, #1c1e29 0%, #111318 60%);
  position: fixed;
  top: 0;
  z-index: 101;
  width: 100%;
}

.centeredItems {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightItems {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.logoStyle {
  color: white;
  text-decoration: none;
  font-size: 24px;
  flex-shrink: 0;
  align-self: flex-start;
}

.linksContainerStyle {
  display: flex;
  gap: 20px;
  align-items: center;
}

.navLinkStyle {
  align-items: center;
  height: 100%;
  color: #f8f9fa;
  text-decoration: none;
  padding: 8px 12px;
  position: relative;
  display: flex;
  align-items: center;
}

.dropdownStyle {
  position: relative;
  color: #f8f9fa;
  align-items: center;
  height: 100%;
}

.dropdownMenuStyle {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 100%;
  left: 0;
  background: radial-gradient(circle at 50% 50%, #4b4e6d 0%, #2c2d33 60%);
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  transition: all 0.3s ease, visibility 0s linear 0.3s;
  overflow: hidden;
  width: 250px;
}

.dropdownLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 10px 30px;
  border-bottom: 1px solid #f8f9fa;
  transition: background-color 0.3s ease;
  color: black;
}

.iconColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}

.iconStyle {
  margin-bottom: 8px;
  color: #0070c0;
}

.textColumn {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.mainText {
  font-weight: bold;
}

.dropdownSubtext {
  font-size: 0.9em;
  color: grey;
}

.dropdownMenuStyle a:last-child {
  border-bottom: none;
}

.dropdownMenuStyle a:hover {
  background-color: #f9f9f9;
}

.dropdownStyle:hover .dropdownMenuStyle {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s, 0s;
}

.hamburgerMenu {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 100;
}

/* CSS for changing the color of the 'Practice' text to white */
.navBarStyle .dropdownStyle:first-child > div:first-child {
  color: #ffffff !important;
}

.logoutButtonStyle {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.upgradeButtonStyle {
  padding: 0.65rem 1.2rem;
  font-size: 0.85rem;
  font-weight: 500;
  border-radius: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid #ffd700;
  background: #ffd700;
  color: #333;
  margin-left: 10px;
  text-decoration: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: flex; /* Add this line */
  align-items: center; /* Add this line */
}

.upgradeButtonStyle:hover {
  background: #ffec8b;
  color: #222;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

.starsStyle {
  width: 20px;
  height: 20px;
  padding-right: 1px;
  padding-left: 1px;
}

.startFreeButtonStyle {
  background-color: #1e90ff; /* DodgerBlue background */
  border: none; /* No border */
  color: white; /* White text */
  padding: 15px 32px; /* Padding */
  text-align: center; /* Centered text */
  text-decoration: none; /* No underline */
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer; /* Pointer cursor on hover */
  transition-duration: 0.4s; /* Transition for hover effect */
  border-radius: 25px; /* Rounded corners */
}

.startFreeButtonStyle:hover {
  background-color: #104e8b; /* Darker DodgerBlue on hover */
}

/* Media Query for Tablets and Smaller Devices */
@media (max-width: 768px) {
  .navBarStyle {
    padding: 15px 20px;
  }

  .hamburgerMenu {
    display: block;
    font-size: 24px;
  }

  .centeredItems,
  .rightItems,
  .dropdownMenuStyle {
    display: none; /* Hide desktop menu items */
  }

  .linksContainerStyle {
    /* Adjust styles for mobile menu layout */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #222;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 60px;
  }

  .navLinkStyle {
    font-size: 16px;
    padding: 15px 20px;
  }

  .startFreeButtonStyle,
  .upgradeButtonStyle {
    font-size: 16px;
    padding: 10px 20px;
  }

  .mobileMenu {
    display: flex; /* Changed from block to flex for center alignment */
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    position: fixed; /* Fixed position to cover the entire screen */
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh; /* Full viewport height */
    background-color: rgba(0, 0, 0, 1); /* Black background */
    z-index: 100; /* Ensure it's above other content */
    padding: 20px; /* Add some padding */
  }

  /* Style for Links inside Mobile Menu */
  .mobileMenu a {
    color: white; /* White text color for the links */
    font-size: 24px; /* Increased font size for better readability */
    padding: 10px 0; /* Add padding for tap targets */
    text-decoration: none; /* Remove underline from links */
    transition: color 0.3s ease; /* Transition for hover effect */
  }

  /* Hover effect for links */
  .mobileMenu a:hover {
    color: #ccc; /* Lighter text color on hover */
  }

  /* Backdrop styles */
  .backdrop {
    display: block; /* Ensure backdrop is visible when menu is open */
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent backdrop */
    z-index: 99; /* Right below the mobile menu */
  }

  /* Close Button inside Mobile Menu */
  .closeMenu {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    color: white; /* Match the color of the text links */
    font-size: 24px; /* Large enough for easy interaction */
    cursor: pointer;
  }
}

/* Media Query for Mobile Phones */
@media (max-width: 480px) {
  .logoStyle {
    font-size: 20px;
  }

  .navBarStyle {
    padding: 12px 15px;
  }

  .navLinkStyle {
    font-size: 14px;
    padding: 12px 15px;
  }

  .startFreeButtonStyle,
  .upgradeButtonStyle {
    font-size: 14px;
    padding: 8px 15px;
  }

  .mobileMenu {
    display: flex; /* Changed from block to flex for center alignment */
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    position: fixed; /* Fixed position to cover the entire screen */
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh; /* Full viewport height */
    background-color: rgba(0, 0, 0, 1); /* Black background */
    z-index: 100; /* Ensure it's above other content */
    padding: 20px; /* Add some padding */
  }

  /* Style for Links inside Mobile Menu */
  .mobileMenu a {
    color: white; /* White text color for the links */
    font-size: 24px; /* Increased font size for better readability */
    padding: 10px 0; /* Add padding for tap targets */
    text-decoration: none; /* Remove underline from links */
    transition: color 0.3s ease; /* Transition for hover effect */
  }

  /* Hover effect for links */
  .mobileMenu a:hover {
    color: #ccc; /* Lighter text color on hover */
  }

  /* Backdrop styles */
  .backdrop {
    display: block; /* Ensure backdrop is visible when menu is open */
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent backdrop */
    z-index: 99; /* Right below the mobile menu */
  }

  /* Close Button inside Mobile Menu */
  .closeMenu {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    color: white; /* Match the color of the text links */
    font-size: 24px; /* Large enough for easy interaction */
    cursor: pointer;
  }

  .hamburgerMenu {
    font-size: 22px;
  }
}
