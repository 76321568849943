.social-proof-section {
  display: flex; /* Enable flexbox */
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
  justify-content: center; /* Center children vertically */
  background-color: #fff;
  color: #333;
  padding: 2rem;
  text-align: center;
  max-width: 100%;
  margin: 0 auto;

  padding-bottom: 4rem;
}

.social-proof-title-container {
  margin-bottom: 1rem; /* Reduced margin bottom */
}

.social-proof-title {
  display: flex;
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Centers content vertically */
  font-size: 2.5rem;
  margin-bottom: 2rem;
  margin-top: 1rem;
  text-align: center;
  height: 100%; /* Takes up the full height of its parent */
}
.centered-text {
  text-align: center;
}

.social-proof-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr)); /* Make the grid responsive */
  gap: 2rem;
  padding: 0; /* Remove any default padding */
  list-style: none; /* In case it's a list */
  justify-content: center; /* Center grid items horizontally */
}

.social-proof-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 1rem; /* Adjusted padding */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 auto; /* Center the items if they are smaller than the grid's repeat value */
}

.social-proof-icon {
  font-size: 2rem;
  color: black;
  margin-bottom: 1rem;
  align-self: center; /* Align the icon in the center */
}

.social-proof-item-title {
  font-size: 1.5rem;

  color: #333;
  margin-bottom: 0.5rem; /* Adjusted margin */
  text-align: center;
}

.social-proof-item-description {
  font-size: 1rem;
  margin-bottom: 0.5rem; /* Adjusted margin */
  text-align: left;
}

.social-proof-item-image-container {
  position: relative; /* Add this to position the pseudo-element */
}
.social-proof-item-image-container img {
  width: 450px; /* Set a specific width */
  height: 450px; /* Set a specific height */
  padding: 10px;
  border-radius: 30px;
  object-fit: cover; /* Change this to 'cover' */
}

.social-proof-item-image-container::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%; /* Adjust this to change the height of the fade out */
  background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); /* Add this to create the fade out effect */
}

.social-proof-cta-container {
  width: 60%;
  margin: 0 auto;
  text-align: center;
  padding: 3rem 0; /* Padding on top and bottom */
}

/* Center the buttons within the cta-container */
.social-proof-buttons {
  text-align: center;
  padding: 1rem 0;
}

/* Ensure that the links within buttons are centered */
.social-proof-buttons a {
  display: inline-block;
  margin: 0 10px;
}

.social-proof-btn-primary,
.social-proof-btn-secondary {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  margin: 0.5rem;
  border: 2px solid transparent;
  transition: all 0.3s ease;
}

.social-proof-btn-primary {
  background-color: #1c1e29;
  color: #ffffff;
  border-radius: 25px;
}

.social-proof-btn-primary:hover,
.social-proof-btn-secondary:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.social-proof-btn-secondary {
  background-color: #ffffff;
  color: #1c1e29;
  border: 2px solid #1c1e29;
  border-radius: 25px;
}

/* Mobile responsiveness */
@media (max-width: 1024px) {
  .social-proof-item-image-container img {
    width: 100%;
    max-width: 300px;
    height: auto;
  }
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .social-proof-title {
    font-size: 1.5rem; /* Smaller title font size */
  }

  .social-proof-grid {
    grid-template-columns: 1fr; /* Single column layout on phones */
  }

  .social-proof-item-description {
    font-size: 0.9rem; /* Smaller description font size */
  }

  .social-proof-buttons {
    display: flex;
    margin: 0.3rem;
    flex-direction: column;
  }

  .social-proof-btn-primary,
  .social-proof-btn-secondary {
    /* width: 100%; */
    margin: 0.8rem 0;
  }

  .social-proof-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }

  .social-proof-cta-container .social-proof-buttons .social-proof-btn-secondary {
    margin-top: 1.6rem;
  }
}
