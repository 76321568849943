/* 
 
 *
  
  /* .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  } */
/* Define the class name */
.stripe-container {
  display: flex;
  flex-direction: row;
  margin: auto;
  /* background-color: #fbfbfb; */
  /* height: 90vh; */
  padding: 100px;
  align-items: center;
  justify-content: center;

}

.stripe-container>div {
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 50px 0;
  width: 40%;

}

.stripe-container .payment_right {
  padding-left: 50px;
  padding-right: 50px;
  max-width: 550px;
  width: 100%;
}

.cvv_wrapper .StripeElement {
  margin-bottom: 0;
}

.stripe-container .payment_right .light-font {
  color: #1f1d1d;
  font-weight: 600;
  font-size: 17px;
}

.StripeElement {
  display: block;
  max-width: 500px;
  padding: 10px 14px;
  /* border-bottom: 1px solid #e2e2e2; */
  /* box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px; */
  /* border-radius: 4px; */
  background: white;
  margin-bottom: 10px;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
}

.stripe-container .pay_btn {
  margin: 0px 63px;
  padding: 10px 40px;
  max-width: 100%;
  align-self: center;
  font-weight: 500;
  background-color: #ffc107;
  color: #222;
  border-radius: 5px;
  width: 100%;
}

.product_content {
  max-width: 400px;
}

.product_content h4 {
  font-weight: 600;
}

.stripe-container .form-select.active {
  background-color: #007aff;
  color: white;
  cursor: pointer;
}

.stripe-container .form-control.active {
  border: 2px solid #007aff;
  color: #007aff;
}

.stripe-container .dropdown {
  position: absolute;
  width: 100%;
}

.stripe-container .form-control {
  background-color: white;
  z-index: 2;
  cursor: text;
  width: 100%;
  position: relative;
  border-radius: 5px;
  margin: 5px auto;
  padding: 7px 14px;
  box-shadow: 0px 2px 2px 0px #ccc;
}

.cards_number .StripeElement--empty {
  box-shadow: 0px 2px 4px 0px #ccc;
}

.cvv_wrapper .StripeElement--empty {
  box-shadow: 0px 2px 4px 0px #ccc;
}

.box {
  margin-top: 60px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.alert {
  margin-top: 25px;
  background-color: #ffffff00;
  font-size: 25px;
  font-family: sans-serif;
  text-align: center;
  width: 300px;
  height: 100px;
  padding-top: 150px;
  position: relative;

}

.alert::before {
  width: 100px;
  height: 100px;
  position: absolute;
  border-radius: 100%;
  inset: 20px 0px 0px 100px;
  font-size: 60px;
  line-height: 100px;
  border: 5px solid gray;

}

.alert>.alert-body {
  opacity: 0;

}


.success {
  color: green;
}

.success::before {
  content: '✓';
  background-color: #eff;
  box-shadow: 0px 0px 12px 7px rgba(200, 255, 150, 0.8) inset;
  border: 5px solid green;
}

.error {
  color: red;
}

.error::before {
  content: '✗';
  background-color: #fef;
  box-shadow: 0px 0px 12px 7px rgba(255, 200, 150, 0.8) inset;
  border: 5px solid red;
}


.loading {
  height: 0;
  width: 0;
  padding: 15px;
  border: 6px solid #ccc;
  border-right-color: #888;
  border-radius: 22px;
  animation: rotate 1s infinite linear;
  /* left, top and position just for the demo! */
  position: absolute;
  left: 50%;
  top: 50%;
}

@keyframes rotate {

  /* 100% keyframe for  clockwise. 
       use 0% instead for anticlockwise */
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.card_wrapper {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: 5px auto;
  overflow: hidden;
  width: 100%;
}

.cvv_wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  column-gap: 10px;
  padding: 10px 0px;
}

.cvv_wrapper>div {
  flex-grow: 1;

}

.light-font {
  color: #666666;
}

.payment_right input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #97a3af;
  opacity: 0.5;
  /* Firefox */
}

.stripe-container .cards_number {
  position: relative;
}

.stripe-container .cards_number .cards-imgs {
  width: 130px;
  height: 40px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 10px;
}

.stripe-container .cards_number .cards-imgs img {
  width: 100%;
}

.stripe-container .cvclogo {
  position: relative;
}

.stripe-container .cvclogo .cvc_card_logo {
  position: absolute;
  right: 10px;
  top: 5px;
}

.stripe-container .product_img img {
  width: 100%;
}
.cvc_card_logo img{
  width: 29px;
    color: grey;
    filter: brightness(0.6);
}






@media screen and (max-width:575px) {
  .stripe-container {
    flex-wrap: wrap;
    padding: 0;
  }

  .stripe-container>div {
    width: auto;
  }

  .product_content {
    max-width: 100%;
    padding-left: 29px;
  }
  .cards-imgs{
    display: none !important;
  }
}