.announcement-bar {
  background-color: #0070c0;
  color: #fff;
  padding: 6px 0;
  text-align: center;
  font-size: 16px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0; /* These ensure the bar stretches across the screen */
}

p {
  margin: 0;
  padding-right: 16px;
}

.announcement-cta {
  background-color: #fff;
  color: #0070c0;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 12px;
  transition: background-color 0.3s ease, color 0.3s ease;
  text-decoration: none;
}

.announcement-cta:hover {
  background-color: #004a80;
  color: #fff;
}

.hidden {
  display: none;
}

/* Mobile Styles */
@media screen and (max-width: 600px) {
  .announcement-bar {
    font-size: 14px; /* Smaller font size for mobile */
    padding: 4px 0; /* Reduced padding for mobile */
  }

  p {
    padding-right: 8px; /* Less space on mobile */
  }

  .announcement-cta {
    font-size: 12px; /* Smaller button font size */
    padding: 4px 8px; /* Reduced button padding */
  }
}
