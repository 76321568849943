/* QuestionForExam.css */

.question-for-exam {
  margin-bottom: 2rem;
  background: #ffffff; /* Bright white for clarity */
  padding: 20px;
  border-radius: 10px; /* Soft rounded corners */
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05); /* Lighter shadow for depth */
  font-family: "Arial", sans-serif; /* Professional font */
}

.question-text {
  font-size: 1.2rem; /* Slightly larger font for readability */
  line-height: 1.8;
  color: #333; /* Darker text for better contrast */
  margin-bottom: 20px;
}

.answer-option {
  display: flex;
  align-items: center;
  padding: 12px 20px;
  margin: 5px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #f8f8f8;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  font-size: 1rem;
}
/* Customizing the radio button appearance */
.answer-option input[type="radio"] {
  accent-color: #0056b3; /* Color for radio button */
  margin-right: 15px;
  cursor: pointer;
  margin-right: 10px; /* Ensures space between the radio button and label text */
}

/* Styling for when an option is selected */
.answer-option:hover {
  background-color: #e9ecef;
  border-color: #007bff;
}

.selected-answer {
  background-color: #e9ecef; /* Light background for selected answer */
  border-color: #007bff; /* Highlight color for selected answer */
}

.submit-answer {
  width: 100%;
  padding: 15px 0; /* Uniform padding */
  font-size: 1rem;
  margin-top: 25px; /* Consistent margin with the rest of the design */
  background-color: #28a745; /* Positive action color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s; /* Smooth transition for interaction */
}

.submit-answer:hover {
  background-color: #218838; /* Slightly darker green on hover */
}

.submit-answer:disabled {
  background-color: #cccccc; /* Disabled state */
  color: #666; /* Greyed out text */
}
