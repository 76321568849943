/* Start Chat Button Styles */
.start-chat-button {
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  background: linear-gradient(to right, #0070c0, #005999);
  color: white;
  cursor: pointer;
  transition: background 0.3s;
}

.start-chat-button:hover {
  background: linear-gradient(to right, #005999, #0070c0);
}

/* Chatbot Container Styles */
.pei-chatbot-container {
  font-family: "Helvetica Neue", Arial, sans-serif;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

/* Messages Container Styles */
.messages-container {
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 20px;
}

/* Chat Message Styles */
.chat-message {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 10px;
}

.chat-message.user {
  color: #0070c0;
}

.chat-message.bot {
  color: #333;
}

/* User Input Container Styles */
.user-input-container {
  display: flex;
}

.user-input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* Send Button Styles */
.user-input-container button {
  margin-left: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #0070c0;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.user-input-container button:hover {
  background-color: #005999;
}

.user-input-container button:disabled {
  background-color: #999;
  cursor: not-allowed;
}

/* Introduction Container Styles */

.introduction-container {
  display: flex;
  align-items: flex-start; /* Align items at the start of the container */
  justify-content: space-between; /* Space between text and image */
  background-color: #ffffff;
  padding: 40px; /* Consider increasing if more white space is needed */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.introduction-text {
  width: 60%; /* Adjust text width to give space for the image */
  text-align: left;
  padding-right: 20px; /* Adding padding to the right of text */
}

.introduction-text h2 {
  margin-bottom: 0.5em; /* Spacing after the heading */
  font-size: 2em; /* Larger font size for heading */
  line-height: 1.2; /* Tighter line height for headings */
  color: #005999; /* A color that stands out */
}

.introduction-text p {
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 1em; /* Adjust font size as needed */
  line-height: 1.6; /* Increase line height for better readability */
  margin-bottom: 1em; /* Space between paragraphs */
  letter-spacing: 0.5px; /* Slight increase in letter spacing */
  color: #4a4a4a; /* Soft black for body text */
}

.introduction-image {
  width: 30%; /* Adjust image width to the desired size */
  margin-left: 20px; /* Add some space between the text and the image */
  display: flex;
  justify-content: center; /* Center the image in the available space */
}

.introduction-image img {
  width: 100%; /* Make the image responsive within its container */
  border-radius: 8px; /* Optional: if you want rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: to give depth to the image */
}
.chatbot-container {
  /* Ensures that everything is contained and centered */
  max-width: 1200px;
  margin: auto;
}

.pei-intro-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.pei-intro-text {
  /* Allows text to take necessary space while leaving room for the image */
  flex: 1;
  margin-right: 20px;
}

.pei-intro-image {
  /* Adjusts the image container to not stretch and just wrap the image */
  flex-shrink: 0;
}

.pei-intro-image img {
  width: 100%; /* Ensures image is responsive within its container */
  max-width: 300px; /* Adjust the max-width as needed to fit the image size */
  height: auto;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.chat-interface {
  /* This will keep the chat interface below the intro container */
  margin-top: 20px;
}

/* End Chat Message Styles */
.end-chat-message {
  color: #ff0000;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  opacity: 0; /* Start with the message invisible */
  transition: opacity 2s ease-in-out; /* Animate the opacity over 2 seconds */
}

.end-chat-message.visible {
  opacity: 1; /* Make the message visible when the 'visible' class is added */
}

/* Question Type Selector Styles */
.verbal-questionnaire {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Adjust as needed */
}

.type-buttons-container {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 20px !important; /* Adjust the space between buttons */
  margin-top: 70px !important;
}

.type-button {
  padding: 10px 15px !important;
  border: none !important;
  color: #000;
  background-color: transparent !important;
  cursor: pointer !important;
  font-size: 16px !important;
  transition: color 0.3s !important;
  position: relative !important;
  text-align: center !important;
  text-transform: capitalize !important; /* This will capitalize the first letter of each word */
}

.type-button .icon {
  display: block !important; /* Replace with actual icons */
  margin-bottom: 8px !important; /* Space between icon and text */
}

.type-button .underline {
  height: 3px !important;
  background-color: black !important; /* Color of the underline */
  width: 100% !important;
  position: absolute !important;
  bottom: -10px !important; /* Adjust as needed */
  left: 0 !important;
}

.type-button:hover,
.type-button.active {
  color: #000 !important; /* Text color for active/hover state */
}

/* Define styles for icons based on active state */
.icon {
  /* Default icon styles */
}

.icon-active {
  /* Active icon styles */
}

@media (max-width: 768px) {
  .pei-intro-container {
    flex-direction: column; /* Stack elements on smaller screens */
    padding: 20px; /* Less padding on smaller screens */
  }

  .pei-intro-text {
    margin-right: 0; /* No margin needed when stacked */
    margin-bottom: 20px; /* Space before the image */
  }

  .pei-intro-image img {
    max-width: 100%; /* Full width on smaller screens */
    height: auto; /* Adjust height automatically */
  }
  .introduction-container {
    flex-direction: column;
  }

  .introduction-text,
  .introduction-image {
    width: 100%; /* Full width on smaller screens */
  }

  .introduction-image {
    margin-top: 20px; /* Add space between text and image */
  }
}
